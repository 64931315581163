<template>
    <div>
        <v-container fluid class="pa-6 ">
            <DatatableV2 :displayTitle="false" :flat="false" :url="apiUrl" switchUrl="/form/switchActive" switchValue="active" switchIds="id" @openSubmissions="openSubmissions" @openLibraryDialog="openLibraryDialog" @copyIntegrationScript="copyIntegrationScript" ref="formDatatable" />
            <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="libType" @cancel="formLib = null;" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" @customRefresh="customRefresh" />
            <submissionDialogComponent v-if="openDialogSubmissions" :openDialogSubmissions="openDialogSubmissions" :formId="formId" @closeDialog="openDialogSubmissions = false" />
        </v-container>
    </div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import DatatableV2 from '@/components/DatatableV2';
import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";
import submissionDialogComponent from '@/components/submissionDialogComponent.vue';
export default {
    name: "FormList",
    components: { DatatableV2, LibraryDialogComponent, submissionDialogComponent },
    data() {
        return {
            apiUrl: "/form/list",
            formLib: null,
            libType: "FORM",
            showLibraryDialog: false,
            openDialogSubmissions: false,
            formId: null
        };
    },
    methods: {
        openSubmissions($evt){
            this.formId = $evt;
            this.openDialogSubmissions = true;
        },
        openLibraryDialog() {
            GenericDataService.getData("/library/getFilters?libType=" + this.libType).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
            });
        },
        customRefresh() {
            this.$refs.formDatatable.readDataFromAPI();
        },
        copyIntegrationScript(id) {
            const textToCopy = `<script src="${this.$store.state.auth.apiUrl}/views/survey.js" class="survey-script" data-id="${id}"><\/script>`;

            navigator.clipboard.writeText(textToCopy).then(() => {
                this.$store.dispatch('ADD_SNACK', { message : this.$t('scriptCopied') }).then((e) => {
                    this.$store.dispatch("REMOVE_SNACK")
                });
            });
        }
    },
};
</script>
